export enum FieldType{
    Text,
    CheckBox,
    Radio,
    Number,
    TextArea,
    File,
    Date,
    SwitchButton,
    MultiSelect,
    Select,
    AsyncSelect,
    AsyncMultiSelect,
    Password,
    Email,
    Time,
    DateTime,
    Child,
    SelectHavingHTMLOptions
}