import React, {Suspense} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {AuthInit} from './modules/auth'
import {DashboardEditorProvider} from "./contexts/dashboard/DashboardEditorContext";
import AudioCall from "./pages/practices/coordinator/followup/panels/AudioCall";
import {AudioCallProvider} from "./contexts/audioCallContext/AudioCallContext";

const App = () => {

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <AuthInit>
            <DashboardEditorProvider>
              <AudioCallProvider>
                <AudioCall/>
                <Outlet />
                <MasterInit />
              </AudioCallProvider>
            </DashboardEditorProvider>
          </AuthInit>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  )
}

export {App}
