import {Link} from 'react-router-dom'
import {KTSVG} from '../../../../helpers'
import {Dropdown1, Search} from '../../../../partials'
import {useDashboardEditorContext} from "../../../../../app/contexts/dashboard/DashboardEditorContext";
import {defaultRowId} from "../../../../../app/panelConfiguration/constants/others";


const DashboardsTab = () => {
    const {dashboards, setDashboards} = useDashboardEditorContext()

    const addNewDashboard = () => {
        setDashboards([
            ...dashboards,
            {
                id: dashboards && dashboards.length > 0 ? dashboards[dashboards.length -1].id + 1 : 1,
                icon: "/media/icons/duotune/general/gen008.svg",
                name: "New Dashboard",
                rows: [
                        {
                            id: defaultRowId,
                            name: ' Default Row',
                            gridConfigs: { i: defaultRowId, x:0, y:0, w: 12, h:3 },
                            panels: []
                        }
                    ]
            }
        ])
    }

    return (
        <div className='m-0'>
            {/* begin::Toolbar */}
            <div className='d-flex mb-10'>
                <Search/>
                {/* begin::Filter */}
                <div className='flex-shrink-0 ms-2'>
                    {/* begin::Menu toggle */}
                    <button
                        type='button'
                        className='btn btn-icon btn-bg-light btn-active-icon-primary btn-color-gray-400'
                        data-kt-menu-trigger='click'
                        data-kt-menu-placement='bottom-end'
                    >
                        <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2'/>
                    </button>
                    {/* end::Menu toggle */}

                    <Dropdown1/>
                </div>
                {/* end::Filter */}
            </div>
            {/* end::Toolbar */}

            {/*begin::Projects*/}
            <div className='m-0'>
                {/*begin::Heading*/}
                <div className="d-flex justify-content-between mb-6">
                    <h1 className='text-gray-800 fw-bold mx-5'>
                        <Link to={'/dashboards'}>
                            Dashboards
                        </Link>
                    </h1>
                    <div onClick={addNewDashboard} className="me-4 d-flex justify-content-center" style={{cursor: 'pointer'}}><KTSVG
                        path="/media/icons/duotune/general/gen041.svg" className="svg-icon-primary ps-2  svg-icon-2x"/>
                    </div>
                </div>
                {/*end::Heading*/}

                {/*begin::Items*/}
                <div className='mb-10'>
                    {dashboards && dashboards.length > 0 && dashboards.slice(0).reverse().map((p, index) => (
                            <Link
                                key={`dashboard-list-${p.id}-${p.name}-${index}`}
                                to={`/dashboard/${p.id}`}
                                className='custom-list d-flex align-items-center px-5 py-4'
                            >
                                <div className='symbol symbol-40px me-5'>
                                    <span className='symbol-label bg-light-success'>
                                        <KTSVG path={p.icon} className={p.iconClass? p.iconClass : 'svg-icon-2hx svg-icon-primary'}/>
                                    </span>
                                </div>
                                {/*end::Symbol*/}

                                {/*begin::Description*/}
                                <div className='d-flex flex-column flex-grow-1'>
                                    {/*begin::Title*/}
                                    {<h5 className='custom-list-title fw-bold text-gray-800 mb-1'>{p.name}</h5>}

                                    {/*end::Title*/}

                                    {/*begin::Link*/}
                                    <span className='text-gray-400 fw-bold'>{`last updated 3 min ago `}</span>
                                    {/*end::Link*/}
                                </div>
                                {/*begin::Description*/}
                            </Link>
                    ))}
                </div>
                {/*end::Items*/}
            </div>
            {/*end::Projects*/}
        </div>
    )
}

export {DashboardsTab}
