import {FieldType} from "../../enums/FieldType";
import {IPanelOption} from "../../interfaces/panel/IPanelOption";

const title : IPanelOption = {
    title: "Title",
    optionName: 'title',
    isShow: true,
    fields: [
        {
            label: "Main Title",
            name: "text",
            type: FieldType.Text,
            tooltipMessage: 'any',
            valueType: "text",
            isRequired: false,
            value: ''
        },
        {
            name: "textStyle",
            type: FieldType.Child,
            tooltipMessage: 'any',
            valueType: "object",
            isRequired: false,
            childFields: [
                {
                    label: "Main Title Font Size",
                    name: "fontSize",
                    type: FieldType.Number,
                    tooltipMessage: 'any',
                    valueType: "number",
                    isRequired: false,
                    value: 15
                }
            ]
        },
        {
        label: "Sub Title",
        name: "subtext",
        type: FieldType.Text,
        tooltipMessage: 'any',
        valueType: "text",
        isRequired: false,
        value: ''
    },
        {
            name: "subtextStyle",
            type: FieldType.Child,
            tooltipMessage: 'any',
            valueType: "object",
            isRequired: false,
            childFields: [
                {
                    label: "Sub Title Font Size",
                    name: "fontSize",
                    type: FieldType.Number,
                    tooltipMessage: 'any',
                    valueType: "number",
                    isRequired: false,
                    value: 15
                }
            ]
        },
        {
        label: "Left",
        name: 'left',
        type: FieldType.Select,
        tooltipMessage: 'any',
        valueType: "text",
        isRequired: false,
        value: {label: 'Auto', value: 'auto'},
        selectOptions: [
            {label: 'Auto', value: 'auto'},
            {label: 'Left', value: 'left'},
            {label: 'Center', value: 'center'},
            {label: 'Right', value: 'right'}
        ]
    },
        {
        label: "Top",
        name: 'top',
        type: FieldType.Select,
        tooltipMessage: 'any',
        valueType: "text",
        isRequired: false,
        value: {label: 'Auto', value: 'auto'},
        selectOptions: [
            {label: 'Auto', value: 'auto'},
            {label: 'Top', value: 'top'},
            {label: 'Middle', value: 'middle'},
            {label: 'Bottom', value: 'bottom'}
        ]
    }
]
}

export default  title