/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {HeaderUserMenu, ThemeModeSwitcher} from '../../../partials'
import {useLocation} from "react-router-dom";
import {Settings} from "../../../../app/pages/dashboard/settings/Settings"
import {useLayout} from "../../core";

const Topbar: FC = () => {
    const {newPanel, setNewPanel} = useLayout()
    const {pathname} = useLocation()
    return <div className='d-flex flex-shrink-0'>
        {/* begin::Add New Panel/Dashboard */}
        {/* eslint-disable-next-line react-hooks/rules-of-hooks */}
        {pathname.includes('dashboard/') && !pathname.includes('dashboard-editor') && !pathname.includes('panel-detail') &&
            <><div className='d-flex ms-3' onClick={() => { setNewPanel(!newPanel) }}>
                <button className='btn btn-flex flex-center bg-body btn-color-gray-700 btn-active-color-primary w-40px w-md-auto h-40px px-0 px-md-6'>
                    <KTSVG
                        path='/media/icons/duotune/arrows/arr075.svg'
                        className='svg-icon-2 svg-icon-primary me-0 me-md-2'
                    />
                    <KTSVG path="/media/icons/duotune/graphs/gra004.svg" className="svg-icon-muted svg-icon-2hx"/>
                </button>
            </div>
        {/* begin::Settings */}
            <div className='d-flex align-items-center  ms-3'>
            <Settings toggleBtnClass2=' flex-center bg-body btn-color-gray-600 btn-active-color-primary h-40px' />
            </div>
        {/* end::Settings*/}
            </>
        }

        {/* end::Add New Panel/Dashboard */}



    {/* begin::Theme mode */}
    <div className='d-flex align-items-center  ms-3'>
      <ThemeModeSwitcher toggleBtnClass=' flex-center bg-body btn-color-gray-600 btn-active-color-primary h-40px' />
    </div>
    {/* end::Theme mode */}

        <div className='d-flex align-items-center ms-3' id='kt_header_user_menu_toggle'>
            {/* begin::Menu wrapper */}
            <div
                className='cursor-pointer symbol symbol-40px'
                data-kt-menu-trigger='click'
                data-kt-menu-overflow='false'
                data-kt-menu-placement='top-start'
                title='User profile'
            >
                <img src={toAbsoluteUrl('/media/avatars/300-1.jpg')} alt='avatar' />
            </div>
            {/* end::Menu wrapper */}
            <HeaderUserMenu />
        </div>

    </div>
}

export {Topbar}
