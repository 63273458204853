import {IMutationRequest, IRequestObject} from "../interfaces/QueryInterfaces";
import http, {baseURL} from "../../../../utility/api/http";
import {useMutation, useQueryClient} from "react-query";

//mutation hook used for add/update/delete
const mutationFunction = ({ url, data, method, responseType }: IRequestObject) => {
    return http._request({baseURL, url , method, data, responseType })
}

export const useMutationDefault = (request: IMutationRequest) => {
    const queryClient = useQueryClient()

    return useMutation(mutationFunction , request.options ? {
        ...request.options,
        onSuccess:  (data) => {
            // if onSuccess is passed in options
            if(request.options.onSuccess) request.options.onSuccess(data)

            if(request.invalidateQuery) {
                queryClient.invalidateQueries(request.invalidateQuery)
            }
        }
    }:{
        onSuccess:  (data) => {
            if(request.invalidateQuery) {
                queryClient.invalidateQueries(request.invalidateQuery)
            }
        }
    })
}