import {FieldType} from "../../enums/FieldType";
import {IPanelOption} from "../../interfaces/panel/IPanelOption";

export const tooltip: IPanelOption = {
    title: "Tooltip",
    optionName: 'tooltip',
    isShow: true,
    fields: [
    {
        label: "Trigger",
        name: "trigger",
        type: FieldType.Select,
        tooltipMessage: 'tooltip',
        valueType: "text",
        isRequired: false,
        value: {label: 'Item', value: 'item'},
        selectOptions: [
            {label: 'Item', value: 'item'},
            {label: 'Axis', value: 'axis'},
            {label: 'None', value: 'none'}
        ]
    }]
}