export const iconList = [
  {label: 'Cart', value: '/media/icons/duotune/ecommerce/ecm001.svg'},
  {label: 'E-commerce-2', value: '/media/icons/duotune/ecommerce/ecm002.svg'},
  {label: 'Percentage Card', value: '/media/icons/duotune/ecommerce/ecm003.svg'},
  {label: 'Shop', value: '/media/icons/duotune/ecommerce/ecm004.svg'},
  {label: 'Shopping Bag', value: '/media/icons/duotune/ecommerce/ecm005.svg'},
  {label: 'Truck', value: '/media/icons/duotune/ecommerce/ecm006.svg'},
  {label: 'Gift', value: '/media/icons/duotune/ecommerce/ecm007.svg'},
  {label: 'Bill', value: '/media/icons/duotune/ecommerce/ecm008.svg'},
  {label: 'E-commerce-9', value: '/media/icons/duotune/ecommerce/ecm009.svg'},

  {label: 'Table Layout', value: '/media/icons/duotune/layouts/lay001.svg'},
  {label: 'Grid Layout', value: '/media/icons/duotune/layouts/lay002.svg'},
  {label: 'Liner Layout', value: '/media/icons/duotune/layouts/lay003.svg'},
  {label: 'Liner Layout-2', value: '/media/icons/duotune/layouts/lay004.svg'},
  {label: 'Liner Layout-3', value: '/media/icons/duotune/layouts/lay005.svg'},
  {label: 'Relative Layout', value: '/media/icons/duotune/layouts/lay006.svg'},
  {label: 'Relative Layout-2', value: '/media/icons/duotune/layouts/lay007.svg'},
  {label: 'Relative Layout-3', value: '/media/icons/duotune/layouts/lay008.svg'},
  {label: 'Relative Layout-4', value: '/media/icons/duotune/layouts/lay009.svg'},

  {label: 'Track', value: '/media/icons/duotune/communication/com001.svg'},
  {label: 'Message', value: '/media/icons/duotune/communication/com002.svg'},
  {label: 'Message Two', value: '/media/icons/duotune/communication/com003.svg'},
  {label: 'Notification Bell', value: '/media/icons/duotune/communication/com004.svg'},
  {label: 'Contact List', value: '/media/icons/duotune/communication/com005.svg'},
  {label: 'Profile', value: '/media/icons/duotune/communication/com006.svg'},
  {label: 'Chat', value: '/media/icons/duotune/communication/com007.svg'},
  {label: 'Thread', value: '/media/icons/duotune/communication/com008.svg'},
  {label: 'Pin', value: '/media/icons/duotune/communication/com009.svg'},

  {label: 'Home', value: '/media/icons/duotune/general/gen001.svg'},
  {label: 'Rocket', value: '/media/icons/duotune/general/gen002.svg'},
  {label: 'Star', value: '/media/icons/duotune/general/gen003.svg'},
  {label: 'Search', value: '/media/icons/duotune/general/gen004.svg'},
  {label: 'File', value: '/media/icons/duotune/general/gen005.svg'},
  {label: 'Picture', value: '/media/icons/duotune/general/gen006.svg'},
  {label: 'Bell', value: '/media/icons/duotune/general/gen007.svg'},
  {label: 'Apps', value: '/media/icons/duotune/general/gen008.svg'},
  {label: 'DualScreen Vertical', value: '/media/icons/duotune/general/gen009.svg'},
  {label: 'DualScreen Horizontal', value: '/media/icons/duotune/general/gen010.svg'},
  {label: 'Square Circle', value: '/media/icons/duotune/general/gen011.svg'},
  {label: 'QuickSand Time', value: '/media/icons/duotune/general/gen012.svg'},
  {label: 'Clock', value: '/media/icons/duotune/general/gen013.svg'},
  {label: 'Calender', value: '/media/icons/duotune/general/gen014.svg'},
  {label: 'Disk', value: '/media/icons/duotune/general/gen015.svg'},
  {label: 'Sent', value: '/media/icons/duotune/general/gen016.svg'},
  {label: 'Square Box', value: '/media/icons/duotune/general/gen017.svg'},
  {label: 'Location', value: '/media/icons/duotune/general/gen018.svg'},
  {label: 'Switch', value: '/media/icons/duotune/general/gen019.svg'},
  {label: 'Trophy', value: '/media/icons/duotune/general/gen020.svg'},
  {label: 'Search-2', value: '/media/icons/duotune/general/gen021.svg'},
  {label: 'App Styled', value: '/media/icons/duotune/general/gen022.svg'},
  {label: 'More', value: '/media/icons/duotune/general/gen023.svg'},
  {label: 'Apps Small', value: '/media/icons/duotune/general/gen024.svg'},
  {label: 'Apps Large', value: '/media/icons/duotune/general/gen025.svg'},
  {label: 'Apps Medium', value: '/media/icons/duotune/general/gen025.svg'},
  {label: 'Settings Tick', value: '/media/icons/duotune/general/gen026.svg'},
  {label: 'Delete', value: '/media/icons/duotune/general/gen027.svg'},
  {label: 'MultiScreen Filled', value: '/media/icons/duotune/general/gen028.svg'},
  {label: 'Star General', value: '/media/icons/duotune/general/gen029.svg'},
  {label: 'Heart', value: '/media/icons/duotune/general/gen030.svg'},
  {label: 'Glass Square', value: '/media/icons/duotune/general/gen031.svg'},
  {label: 'Bar', value: '/media/icons/duotune/general/gen032.svg'},
  {label: 'MoreSquare Vertical', value: '/media/icons/duotune/general/gen033.svg'},
  {label: 'Cancel Square', value: '/media/icons/duotune/general/gen034.svg'},
  {label: 'Plus Square', value: '/media/icons/duotune/general/gen035.svg'},
  {label: 'Subtract Square', value: '/media/icons/duotune/general/gen036.svg'},
  {label: 'Tick Square', value: '/media/icons/duotune/general/gen037.svg'},
  {label: 'MoreCircle Horizontal', value: '/media/icons/duotune/general/gen038.svg'},
  {label: 'MoreCircle Vertical', value: '/media/icons/duotune/general/gen039.svg'},
  {label: 'Cancel Circle', value: '/media/icons/duotune/general/gen040.svg'},
  {label: 'Plus Circle', value: '/media/icons/duotune/general/gen041.svg'},
  {label: 'Subtract Circle', value: '/media/icons/duotune/general/gen042.svg'},
  {label: 'Tick Circle', value: '/media/icons/duotune/general/gen043.svg'},
  {label: 'ExclamationMark Circle', value: '/media/icons/duotune/general/gen044.svg'},
  {label: 'Inform Circle', value: '/media/icons/duotune/general/gen045.svg'},
  {label: 'QuestionMark Circle', value: '/media/icons/duotune/general/gen046.svg'},
  {label: 'Lock Circle', value: '/media/icons/duotune/general/gen047.svg'},
  {label: 'Tick Shield', value: '/media/icons/duotune/general/gen048.svg'},
  {label: 'Profile Shield', value: '/media/icons/duotune/general/gen049.svg'},
  {label: 'Cancel Shield', value: '/media/icons/duotune/general/gen050.svg'},
  {label: 'Lock Shield', value: '/media/icons/duotune/general/gen051.svg'},
  {label: 'More Horizontal', value: '/media/icons/duotune/general/gen052.svg'},
  {label: 'More Vertical', value: '/media/icons/duotune/general/gen053.svg'},
  {label: 'Multi Screen', value: '/media/icons/duotune/general/gen054.svg'},

  {label: 'Text', value: '/media/icons/duotune/art/art001.svg'},
  {label: 'Art-2', value: '/media/icons/duotune/art/art002.svg'},
  {label: 'Brush', value: '/media/icons/duotune/art/art003.svg'},
  {label: 'Eraser', value: '/media/icons/duotune/art/art004.svg'},
  {label: 'Pencil', value: '/media/icons/duotune/art/art005.svg'},
  {label: 'Crop', value: '/media/icons/duotune/art/art006.svg'},
  {label: 'Paint', value: '/media/icons/duotune/art/art007.svg'},
  {label: 'Pen', value: '/media/icons/duotune/art/art008.svg'},
  {label: 'QuickSelection Tool', value: '/media/icons/duotune/art/art009.svg'},

  {label: 'Left Justify', value: '/media/icons/duotune/text/txt001.svg'},
  {label: 'Center Justify', value: '/media/icons/duotune/text/txt002.svg'},
  {label: 'Right Justify', value: '/media/icons/duotune/text/txt003.svg'},
  {label: 'Right Justify-2', value: '/media/icons/duotune/text/txt004.svg'},
  {label: 'Bold', value: '/media/icons/duotune/text/txt005.svg'},
  {label: 'Italic', value: '/media/icons/duotune/text/txt006.svg'},
  {label: 'UnderLine', value: '/media/icons/duotune/text/txt007.svg'},
  {label: 'Text Style', value: '/media/icons/duotune/text/txt008.svg'},
  {label: 'Text Align', value: '/media/icons/duotune/text/txt009.svg'},

  {label: 'Abstract-1', value: '/media/icons/duotune/abstract/abs001.svg'},
  {label: 'ZigZag', value: '/media/icons/duotune/abstract/abs002.svg'},
  {label: 'Abstract-2', value: '/media/icons/duotune/abstract/abs003.svg'},
  {label: 'Diamond', value: '/media/icons/duotune/abstract/abs004.svg'},
  {label: 'Diamond-2', value: '/media/icons/duotune/abstract/abs005.svg'},
  {label: 'SquareBoxInCircle', value: '/media/icons/duotune/abstract/abs006.svg'},
  {label: 'Cloud Light', value: '/media/icons/duotune/abstract/abs007.svg'},
  {label: 'Octagon', value: '/media/icons/duotune/abstract/abs008.svg'},
  {label: 'Circle Transparent', value: '/media/icons/duotune/abstract/abs009.svg'},
  {label: 'Square Transparent', value: '/media/icons/duotune/abstract/abs010.svg'},
  {label: 'Plus Abstract', value: '/media/icons/duotune/abstract/abs011.svg'},
  {label: 'Multiply Abstract', value: '/media/icons/duotune/abstract/abs012.svg'},
  {label: 'Loading Abstract', value: '/media/icons/duotune/abstract/abs013.svg'},
  {label: 'OctagonInsideOctagon', value: '/media/icons/duotune/abstract/abs014.svg'},
  {label: 'Menu Options', value: '/media/icons/duotune/abstract/abs015.svg'},
  {label: 'Abstract-16', value: '/media/icons/duotune/abstract/abs016.svg'},
  {label: 'Abstract-17', value: '/media/icons/duotune/abstract/abs017.svg'},
  {label: 'Abstract Wifi', value: '/media/icons/duotune/abstract/abs018.svg'},
  {label: 'TimeSand Box', value: '/media/icons/duotune/abstract/abs019.svg'},
  {label: 'Triangle Abstract', value: '/media/icons/duotune/abstract/abs020.svg'},
  {label: 'Browser Abstract', value: '/media/icons/duotune/abstract/abs021.svg'},
  {label: 'Circle Chain', value: '/media/icons/duotune/abstract/abs022.svg'},
  {label: 'Power', value: '/media/icons/duotune/abstract/abs023.svg'},
  {label: 'Star Abstract', value: '/media/icons/duotune/abstract/abs024.svg'},
  {label: 'Star Abstract-2', value: '/media/icons/duotune/abstract/abs025.svg'},
  {label: 'OctagonWithShadow', value: '/media/icons/duotune/abstract/abs026.svg'},
  {label: 'Stack Options', value: '/media/icons/duotune/abstract/abs027.svg'},
  {label: 'AbstractDiamond-3', value: '/media/icons/duotune/abstract/abs028.svg'},
  {label: 'Apps Circle', value: '/media/icons/duotune/abstract/abs029.svg'},
  {label: 'GridLayoutCircle', value: '/media/icons/duotune/abstract/abs030.svg'},
  {label: 'GridLayoutCircleSmall', value: '/media/icons/duotune/abstract/abs031.svg'},
  {label: 'TriangleInCircle', value: '/media/icons/duotune/abstract/abs032.svg'},
  {label: 'DiamondInCircle', value: '/media/icons/duotune/abstract/abs033.svg'},
  {label: 'OctagonInCircle', value: '/media/icons/duotune/abstract/abs034.svg'},
  {label: 'OctagonInCircle-2', value: '/media/icons/duotune/abstract/abs035.svg'},
  {label: 'AbstractCircleInsideCircle', value: '/media/icons/duotune/abstract/abs036.svg'},
  {label: 'AbstractCircle-3', value: '/media/icons/duotune/abstract/abs037.svg'},
  {label: 'Flower', value: '/media/icons/duotune/abstract/abs038.svg'},
  {label: 'Abstract Circle-4', value: '/media/icons/duotune/abstract/abs039.svg'},
  {label: 'Google Drive', value: '/media/icons/duotune/abstract/abs040.svg'},
  {label: 'Triangle Drive', value: '/media/icons/duotune/abstract/abs041.svg'},
  {label: 'Frame Square', value: '/media/icons/duotune/abstract/abs042.svg'},
  {label: 'FrameSquare Light', value: '/media/icons/duotune/abstract/abs043.svg'},
  {label: 'Octagon Square', value: '/media/icons/duotune/abstract/abs044.svg'},
  {label: 'Slack', value: '/media/icons/duotune/abstract/abs045.svg'},
  {label: 'BarGraph Abstract', value: '/media/icons/duotune/abstract/abs046.svg'},
  {label: 'Leaf', value: '/media/icons/duotune/abstract/abs047.svg'},
  {label: 'StyledAbstractCircleBackground', value: '/media/icons/duotune/abstract/abs048.svg'},
  {
    label: 'StyledAbstractCircleWithoutBackground',
    value: '/media/icons/duotune/abstract/abs049.svg',
  },
  {label: 'Circle Dark', value: '/media/icons/duotune/abstract/abs050.svg'},
  {label: 'Octagon Dark', value: '/media/icons/duotune/abstract/abs051.svg'},

  {label: 'Right ArrowHead', value: '/media/icons/duotune/arrows/arr001.svg'},
  {label: 'Left ArrowHead', value: '/media/icons/duotune/arrows/arr002.svg'},
  {label: 'Up ArrowHead', value: '/media/icons/duotune/arrows/arr003.svg'},
  {label: 'Down ArrowHead', value: '/media/icons/duotune/arrows/arr004.svg'},
  {label: 'Left DownArrowHead', value: '/media/icons/duotune/arrows/arr005.svg'},
  {label: 'Right DownArrowHead', value: '/media/icons/duotune/arrows/arr006.svg'},
  {label: 'Up RightArrowHead', value: '/media/icons/duotune/arrows/arr007.svg'},
  {label: 'Up LeftArrowHead', value: '/media/icons/duotune/arrows/arr008.svg'},
  {label: 'Plus Sign', value: '/media/icons/duotune/arrows/arr009.svg'},
  {label: 'Minus Sign', value: '/media/icons/duotune/arrows/arr010.svg'},
  {label: 'Multiply Sign', value: '/media/icons/duotune/arrows/arr011.svg'},
  {label: 'Tick Sign', value: '/media/icons/duotune/arrows/arr012.svg'},
  {label: 'Add Circle', value: '/media/icons/duotune/arrows/arr013.svg'},
  {label: 'Minus Circle', value: '/media/icons/duotune/arrows/arr014.svg'},
  {label: 'Multiply Circle', value: '/media/icons/duotune/arrows/arr015.svg'},
  {label: 'Tick Circle-2', value: '/media/icons/duotune/arrows/arr016.svg'},
  {label: 'Plus Square-2', value: '/media/icons/duotune/arrows/arr017.svg'},
  {label: 'Minus Square', value: '/media/icons/duotune/arrows/arr018.svg'},
  {label: 'Multiply Square', value: '/media/icons/duotune/arrows/arr019.svg'},
  {label: 'Tick Square-2', value: '/media/icons/duotune/arrows/arr020.svg'},
  {label: 'Back Arrowheads', value: '/media/icons/duotune/arrows/arr021.svg'},

  {label: 'Files', value: '/media/icons/duotune/files/fil001.svg'},
  {label: 'Grid Files', value: '/media/icons/duotune/files/fil002.svg'},
  {label: 'File-2', value: '/media/icons/duotune/files/fil003.svg'},
  {label: 'File Transparent', value: '/media/icons/duotune/files/fil004.svg'},
  {label: 'File Add', value: '/media/icons/duotune/files/fil005.svg'},
  {label: 'File Minus', value: '/media/icons/duotune/files/fil006.svg'},
  {label: 'File Cross', value: '/media/icons/duotune/files/fil007.svg'},
  {label: 'File Tick', value: '/media/icons/duotune/files/fil008.svg'},
  {label: 'File UpArrow', value: '/media/icons/duotune/files/fil009.svg'},
  {label: 'File UpArrow-2', value: '/media/icons/duotune/files/fil010.svg'},
  {label: 'File Refresh', value: '/media/icons/duotune/files/fil011.svg'},
  {label: 'Folder', value: '/media/icons/duotune/files/fil012.svg'},
  {label: 'Add Folder', value: '/media/icons/duotune/files/fil013.svg'},
  {label: 'Minus Folder', value: '/media/icons/duotune/files/fil014.svg'},
  {label: 'Cross Folder', value: '/media/icons/duotune/files/fil015.svg'},
  {label: 'Tick Folder', value: '/media/icons/duotune/files/fil016.svg'},
  {label: 'Down ArrowFolder', value: '/media/icons/duotune/files/fil017.svg'},
  {label: 'Up ArrowFolder', value: '/media/icons/duotune/files/fil018.svg'},
  {label: 'Folder Refresh', value: '/media/icons/duotune/files/fil019.svg'},
  {label: 'Cloud', value: '/media/icons/duotune/files/fil020.svg'},
  {label: 'Cloud Output', value: '/media/icons/duotune/files/fil021.svg'},
  {label: 'Cloud Input', value: '/media/icons/duotune/files/fil022.svg'},
  {label: 'Cloud InputOutput', value: '/media/icons/duotune/files/fil023.svg'},
  {label: 'File Search', value: '/media/icons/duotune/files/fil024.svg'},

  {label: 'Apple', value: '/media/icons/duotune/social/soc001.svg'},
  {label: 'Android', value: '/media/icons/duotune/social/soc002.svg'},
  {label: 'Windows', value: '/media/icons/duotune/social/soc003.svg'},
  {label: 'Facebook', value: '/media/icons/duotune/social/soc004.svg'},
  {label: 'Instagram', value: '/media/icons/duotune/social/soc005.svg'},
  {label: 'Twitter', value: '/media/icons/duotune/social/soc006.svg'},
  {label: 'Youtube', value: '/media/icons/duotune/social/soc007.svg'},
  {label: 'TikTok', value: '/media/icons/duotune/social/soc008.svg'},
  {label: 'Social-9', value: '/media/icons/duotune/social/soc009.svg'},

  {label: 'Bar Graph', value: '/media/icons/duotune/graphs/gra001.svg'},
  {label: 'Line Graph', value: '/media/icons/duotune/graphs/gra002.svg'},
  {label: 'Line Graph-2', value: '/media/icons/duotune/graphs/gra003.svg'},
  {label: 'Bar Graph-2', value: '/media/icons/duotune/graphs/gra004.svg'},
  {label: 'Bar Graph-3', value: '/media/icons/duotune/graphs/gra005.svg'},
  {label: 'Circle Drive', value: '/media/icons/duotune/graphs/gra006.svg'},
  {label: 'Circle PieChart', value: '/media/icons/duotune/graphs/gra007.svg'},
  {label: 'Circle Graph-2', value: '/media/icons/duotune/graphs/gra008.svg'},
  {label: 'Circle Graph-3', value: '/media/icons/duotune/graphs/gra009.svg'},
  {label: 'Circle Graph-4', value: '/media/icons/duotune/graphs/gra010.svg'},
  {label: 'Line Graph-3', value: '/media/icons/duotune/graphs/gra011.svg'},
  {label: 'Line Graph-4', value: '/media/icons/duotune/graphs/gra012.svg'},

  {label: 'Laptop', value: '/media/icons/duotune/electronics/elc001.svg'},
  {label: 'Phone', value: '/media/icons/duotune/electronics/elc002.svg'},
  {label: 'Tablet', value: '/media/icons/duotune/electronics/elc003.svg'},
  {label: 'Desktop', value: '/media/icons/duotune/electronics/elc004.svg'},
  {label: 'Watch', value: '/media/icons/duotune/electronics/elc005.svg'},
  {label: 'Bluetooth', value: '/media/icons/duotune/electronics/elc006.svg'},
  {label: 'WiFi', value: '/media/icons/duotune/electronics/elc007.svg'},
  {label: 'Router', value: '/media/icons/duotune/electronics/elc008.svg'},
  {label: 'Keyboard', value: '/media/icons/duotune/electronics/elc009.svg'},

  {label: 'Heart Beat', value: '/media/icons/duotune/medicine/med001.svg'},
  {label: 'Medicine Pill', value: '/media/icons/duotune/medicine/med002.svg'},
  {label: 'Media-3', value: '/media/icons/duotune/medicine/med003.svg'},
  {label: 'Test Tubes', value: '/media/icons/duotune/medicine/med004.svg'},
  {label: 'Test Jar', value: '/media/icons/duotune/medicine/med005.svg'},
  {label: 'Thermometer', value: '/media/icons/duotune/medicine/med006.svg'},
  {label: 'Injection', value: '/media/icons/duotune/medicine/med007.svg'},
  {label: 'Virus', value: '/media/icons/duotune/medicine/med008.svg'},
  {label: 'Media-9', value: '/media/icons/duotune/medicine/med009.svg'},

  {label: 'Flag', value: '/media/icons/duotune/maps/map001.svg'},
  {label: 'Map', value: '/media/icons/duotune/maps/map002.svg'},
  {label: 'Route', value: '/media/icons/duotune/maps/map003.svg'},
  {label: 'Map-4', value: '/media/icons/duotune/maps/map004.svg'},
  {label: 'Location', value: '/media/icons/duotune/maps/map005.svg'},
  {label: 'Navigation', value: '/media/icons/duotune/maps/map006.svg'},
  {label: 'TrackLocation', value: '/media/icons/duotune/maps/map007.svg'},
  {label: 'HomeLocation', value: '/media/icons/duotune/maps/map008.svg'},
  {label: 'MultiRoutes', value: '/media/icons/duotune/maps/map009.svg'},

  {label: 'Bank', value: '/media/icons/duotune/finance/fin001.svg'},
  {label: 'CreditCard', value: '/media/icons/duotune/finance/fin002.svg'},
  {label: 'VisitCard', value: '/media/icons/duotune/finance/fin003.svg'},
  {label: 'CentCoin', value: '/media/icons/duotune/finance/fin004.svg'},
  {label: 'Locker', value: '/media/icons/duotune/finance/fin005.svg'},
  {label: 'BriefCase Bag', value: '/media/icons/duotune/finance/fin006.svg'},
  {label: 'Calculator', value: '/media/icons/duotune/finance/fin007.svg'},
  {label: 'Wallet', value: '/media/icons/duotune/finance/fin008.svg'},
  {label: 'Currency', value: '/media/icons/duotune/finance/fin009.svg'},
  {label: 'Dollar', value: '/media/icons/duotune/finance/fin010.svg'},

  {label: 'Settings', value: '/media/icons/duotune/coding/cod001.svg'},
  {label: 'CodeFile', value: '/media/icons/duotune/coding/cod002.svg'},
  {label: 'HtmlTags', value: '/media/icons/duotune/coding/cod003.svg'},
  {label: 'Console', value: '/media/icons/duotune/coding/cod004.svg'},
  {label: 'Loading', value: '/media/icons/duotune/coding/cod005.svg'},
  {label: 'Mouse Pointer', value: '/media/icons/duotune/coding/cod006.svg'},
  {label: 'Link', value: '/media/icons/duotune/coding/cod007.svg'},
  {label: 'UnLink', value: '/media/icons/duotune/coding/cod008.svg'},
  {label: 'Configure', value: '/media/icons/duotune/coding/cod009.svg'},
]
