const panelTypes = {
    bar: 'bar',
    line: 'line',
    gauge: 'gauge',
    heatmap: 'heatmap',
    pie: 'pie',
    scatter: 'scatter',
    iconAndText: 'iconAndText',
    patientDashboardCardsRow: 'patientDashboardCardsRow',
    patientDashboardCalender: 'patientDashboardCalender',
    patientDashboardTitle: 'patientDashboardTitle',
    followUpDashboardCDCodesPanel: 'followUpDashboardCDCodesPanel',
    followUpDashboardCurrentStatusPanel: 'followUpDashboardCurrentStatusPanel',
    followUpDashboardSelectMonthPanel: 'followUpDashboardSelectMonthPanel',
    followUpDashboardPlanPanel: 'followUpDashboardPlanPanel',
    followUpDashboardPlanHistoryPanel: 'followUpDashboardPlanHistoryPanel',
    followUpDashboardSelectDatePanel: 'followUpDashboardSelectDatePanel',
    followUpDashboardPatientInfoPanel: 'followUpDashboardPatientInfoPanel',
    coordinatorDashboardHeader: 'coordinatorDashboardHeader',
    coordinatorDashboardSelection: 'coordinatorDashboardSelection',
    coordinatorDashboardTable: 'coordinatorDashboardTable',
    coordinatorDashboardFollowUp: 'coordinatorDashboardFollowUp',
    doctorDashboardTotalPatients: 'doctorDashboardTotalPatients',
    doctorDashboardAvgHourlyRate: 'doctorDashboardAvgHourlyRate',
    doctorDashboardTotalPractices: 'doctorDashboardTotalPractices',
    doctorDashboardCriticalPatients: 'doctorDashboardCriticalPatients',
    doctorDashboardAppointmentRequests: 'doctorDashboardAppointmentRequests',
    doctorDashboardTodayAppointments: 'doctorDashboardTodayAppointments',
    doctorDashboardJoinCallRoom: 'doctorDashboardJoinCallRoom',
}

export default panelTypes