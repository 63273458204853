import React, {useState} from 'react'
import {Col, Row} from 'reactstrap'
import {Link, useParams} from 'react-router-dom'
import {KTSVG} from '../../../../_metronic/helpers'
import Permissions from './Permissions'
import GeneralSetting from './GeneralSetting'
import Versions from './Versions'

const SettingsPage = () => {
  const [select, setSelect] = useState(1)
  const {id} = useParams()
  return (
    <div>
      <div
        className='d-flex py-5 align-items-center'
        style={{paddingTop: '20px', paddingLeft: '20px', marginBottom: '-20px'}}
      >
        <Link to={`/dashboard/${id}`}>
          <span className='btn btn-active-secondary rounded-pill'>
            <KTSVG path='/media/icons/duotune/arrows/arr002.svg' className='svg-icon-1' />
          </span>
        </Link>
        <h3 style={{marginLeft: '15px'}} className='custom-list-title fw-bold text-gray-800 mb-1'>
          Settings
        </h3>
      </div>
      <Row className='mt-5'>
        <Col lg={3}>
          <div style={{marginTop: '10px'}}>
            <h5
              role='button'
              className={`text-hover-primary fw-bolder ${
                select === 1 ? 'text-primary' : 'text-muted'
              }`}
              onClick={() => setSelect(1)}
            >
              General Settings
            </h5>
            <hr />
            <h5
              role='button'
              className={`text-hover-primary fw-bolder ${
                select === 2 ? 'text-primary' : 'text-muted'
              }`}
              onClick={() => setSelect(2)}
            >
              Versions
            </h5>
            <hr />
            <h5
              role='button'
              className={`text-hover-primary fw-bolder ${
                select === 3 ? 'text-primary' : 'text-muted'
              }`}
              onClick={() => setSelect(3)}
            >
              Permissions
            </h5>
            <hr />
          </div>
        </Col>
        <Col lg={9}>
          {select === 1 ? (
            <div>
              <GeneralSetting />{' '}
            </div>
          ) : (
            ''
          )}
          {select === 2 ? (
            <div>
              <Versions />{' '}
            </div>
          ) : (
            ''
          )}
          {select === 3 ? (
            <div>
              <Permissions />{' '}
            </div>
          ) : (
            ''
          )}
        </Col>
      </Row>
    </div>
  )
}

export default SettingsPage
