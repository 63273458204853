/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useState} from 'react';
import Draggable from "react-draggable";
import {KTSVG, toAbsoluteUrl} from "../../../../../../_metronic/helpers";
import {useAudioCallContext} from "../../../../../contexts/audioCallContext/AudioCallContext";
// @ts-ignore
import AudioWave from "./CallWave";

const AudioCall = () => {
    const {callActive, setCallActive} = useAudioCallContext()
    const [mute, setMute] = useState(false)
    const [call, setCall] = useState(true)
    const [color] = useState('')

    return (
        <>
            {call && callActive && <Draggable>
                <div style={{
                    position: 'fixed', top: '0', right: '0', width: '100%',
                    zIndex: '1280', boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
                    transition: 'all 0.3s ease 0s'
                }}>
                    <div className='card card-custom handle mt-4 me-4 col-3' style={{
                        position: 'fixed', top: '0', right: '0',
                        zIndex: '1280', boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
                        transition: '0.3s'
                    }}>
                        <div className="card-body m-4 " style={{
                            backgroundImage: `url(${toAbsoluteUrl('/media/svg/custom/bg-tile-call-2.svg')})`,
                            backgroundSize: '200px'
                        }}>
                            <div className="d-flex justify-content-end" onClick={() => {
                                setCall(false)
                            }}>
                                <KTSVG path="/media/icons/duotune/arrows/arr014.svg"
                                       className="svg-icon svg-icon-dark svg-icon-2x"/>
                            </div>
                            <div className="d-flex justify-content-center align-self-center">

                                <div className=''>
                                    <div className='mb-5 d-flex justify-content-center'>
                                        <div className='symbol symbol-75px symbol-circle'>
                                            {color ? (
                                                <span
                                                    className={`symbol-label bg-light-${color} text-${color} fs-5 fw-bolder`}>
                SS
              </span>
                                            ) : (
                                                <img alt='Pic' src={toAbsoluteUrl('/media/avatars/300-6.jpg')}/>
                                            )}
                                        </div>
                                    </div>
                                    <a href='#' className='fs-4 text-gray-800 text-hover-primary fw-bolder mb-0'>
                                        Jeunesse Smith
                                    </a>

                                    <div
                                        className='fw-bold text-bg-secondary mb-6 d-flex justify-content-center'>Oakland,
                                        Canada
                                    </div>
                                    <AudioWave />
                                    <div className='fw-bold h4 mb-6 d-flex justify-content-center'>00:23</div>
                                </div>
                            </div>
                            <span className=" d-flex justify-content-center">

                    <button
                        className={`btn  me-2 bi btn-circle ${mute ? 'bi-mic-mute-fill btn-secondary' : 'bi-mic-fill btn-primary'}`}
                        onClick={() => {
                            setMute(!mute)
                        }}
                        style={{textAlign: 'center', padding: '13px 16px'}}></button>
                        <button className="btn btn-danger ms-2 bi bi-telephone-x-fill btn-circle"
                                onClick={() => {
                                    setCallActive(false)
                                }}
                                style={{textAlign: 'center', padding: '13px 16px'}}></button>

                </span>
                        </div>
                    </div>
                </div>

            </Draggable>}


            {!call && callActive && <div className=' d-flex justify-content-center bg-opacity-0  col-12' style={{
                position: 'fixed', top: '-20px', left: '0', right: '0',
                zIndex: '1280',
            }}>
                <div className="col-3 card card-custom" style={{
                    boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
                    transition: '0.3s'
                }}>
                    <div className="mt-5 pe-4 pt-5 ps-4 d-flex justify-content-between">


                        <a href='#' className='fs-4 text-gray-800 text-hover-primary fw-bolder mb-0'>
                            Jeunesse Smith
                        </a>

                        <div className='fw-bold h4 mb-6 d-flex justify-content-start'>00:23</div>
                        <div className="d-flex justify-content-end" onClick={() => {
                            setCall(true)
                        }}>
                            <KTSVG path="/media/icons/duotune/arrows/arr006.svg"
                                   className="svg-icon svg-icon-dark svg-icon-2x"/>
                        </div>

                    </div>
                </div>
            </div>}
        </>
    );
};


export default AudioCall;