import {FieldType} from "../../enums/FieldType";
import {IPanelOption} from "../../interfaces/panel/IPanelOption";

const legend : IPanelOption = {
    title: "Legend",
    optionName: 'legend',
    isShow: true,
    fields: [
    {
        label: "Orient",
        name: 'orient',
        type: FieldType.Select,
        tooltipMessage: 'any',
        valueType: "text",
        isRequired: false,
        value: {label: 'Horizontal', value: 'horizontal'},
        selectOptions: [
            {label: 'Horizontal', value: 'horizontal'},
            {label: 'Vertical', value: 'vertical'}
        ]
    },
        {
        label: "Left",
        name: 'left',
        type: FieldType.Select,
        tooltipMessage: 'any',
        valueType: "text",
        isRequired: false,
        value: {label: 'Auto', value: 'auto'},
        selectOptions: [
            {label: 'Auto', value: 'auto'},
            {label: 'Left', value: 'left'},
            {label: 'Center', value: 'center'},
            {label: 'Right', value: 'right'}
        ]
    }, {
        label: "Top",
        name: 'top',
        type: FieldType.Select,
        tooltipMessage: 'any',
        valueType: "text",
        isRequired: false,
        value: {label: 'Auto', value: 'auto'},
        selectOptions: [
            {label: 'Auto', value: 'auto'},
            {label: 'Top', value: 'top'},
            {label: 'Middle', value: 'middle'},
            {label: 'Bottom', value: 'bottom'}
        ]
    }
]
}

export default legend