import React, {FC} from 'react'
// import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
// import {useIntl} from 'react-intl'
// import {Link, useParams} from 'react-router-dom'
// import {KTSVG} from '../../../../_metronic/helpers'
import PaginationComponent from '../components/PaginationComponent'

const Versions: FC = () => {
  // const intl = useIntl()
  // const {id: dashboardId} = useParams()
  // const dashboardBreadCrumbs: Array<PageLink> = [
  //   {
  //     title: 'Dashboard',
  //     path: '/dashboard',
  //     isSeparator: false,
  //     isActive: false,
  //   },
  // ]
  // const VersionPage = ({id}: any) => {
  return (
    <div>
      {/*<div className='mb-3'>*/}
      {/*  <Link to={`/dashboard/${id}`}>*/}
      {/*    <span className='btn btn-active-secondary rounded-pill'>*/}
      {/*      <KTSVG path='/media/icons/duotune/arrows/arr002.svg' className='svg-icon-1' />*/}
      {/*    </span>*/}
      {/*  </Link>*/}
      {/*</div>*/}
      <table className='table table-striped gy-7 gs-4 fs-5'>
        <thead>
          <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
            <th className='w-20px '></th>
            <th className='w-150px '>Version</th>
            <th className='w-150px '>Date</th>
            <th className='w-150px '>Updated By</th>
            <th className='w-550px '>Notes</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className='w-20px '>
              <div className='form-check form-check-custom form-check-solid'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  value=''
                  id='flexCheckDefault'
                />
              </div>
            </td>
            <td>System Architect</td>
            <td>2011/07/25</td>
            <td>admin</td>
            <td></td>
            <td className='w-60px text-center'>
              <span className='badge badge-primary'>Latest</span>
            </td>
          </tr>
          <tr>
            <td className='w-20px'>
              <div className='form-check form-check-custom form-check-solid'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  value=''
                  id='flexCheckDefault'
                />
              </div>
            </td>
            <td className='fs-4 '>Accountant</td>
            <td>2011/07/25</td>
            <td>admin</td>
            <td></td>
            <td>
              <button className='btn btn-secondary'>restore</button>
            </td>
          </tr>
        </tbody>
      </table>
      <PaginationComponent />
    </div>
  )
}
// return (
//   <>
//     <PageTitle breadcrumbs={dashboardBreadCrumbs}>
//       {intl.formatMessage({id: 'Versions'})}
//     </PageTitle>
//     <VersionPage id={dashboardId} />
//   </>
// )
// }

export default Versions
