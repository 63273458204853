import React, {useEffect, useRef, useState} from 'react'

const AudioWave = () => {
    const [audio, setAudio] = useState<any>(null);
    const currentTheme = localStorage.kt_theme_mode_value

    const getMicrophone = async () => {
        const newAudio = await navigator.mediaDevices.getUserMedia({
            audio: true,
            video: false
        })
        setAudio( newAudio );

    };

    const stopMicrophone = () => {
        audio?.getTracks().forEach((track: { stop: () => any; }) => track.stop());
        setAudio(null);
    };

    useEffect(() => {
        getMicrophone()

        return () => {

            stopMicrophone()
        };
    }, []);

    // @ts-ignore
    const AudioVisualiser = ({ audioData }) => {
        const canvasRef = useRef(null);

        useEffect(() => {
            draw();
        }, [audioData]);

        const draw = () => {
            const canvas = canvasRef.current;
            // @ts-ignore
            const height = canvas.height;
            // @ts-ignore
            const width = canvas.width;
            // @ts-ignore
            const context = canvas.getContext('2d');
            let x = 0;
            const sliceWidth = (width * 1.0) / audioData.length;

            context.lineWidth = 2;
            context.strokeStyle = `${currentTheme === 'dark' ? '#E4E6EFFF': '#323248FF'}`;
            context.clearRect(0, 0, width, height);

            context.beginPath();
            context.moveTo(0, height / 2);
            for (const item of audioData) {
                const y = (item / 255.0) * height;
                context.lineTo(x, y);
                x += sliceWidth;
            }
            context.lineTo(x, height / 2);
            context.stroke();
        };

        return <canvas width="150" height="50" ref={canvasRef} />;
    };


    // @ts-ignore
    const AudioAnalyser = ({ audio }) => {
        const [audioData, setAudioData] = useState<any>(new Uint8Array(1024));
        const audioContextRef = useRef(null);
        const analyserRef = useRef(null);
        const dataArrayRef = useRef(null);
        const sourceRef = useRef(null);
        const rafIdRef = useRef(null);

        useEffect(() => {
            // @ts-ignore
            audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
            // @ts-ignore
            analyserRef.current = audioContextRef.current.createAnalyser();
            // @ts-ignore
            dataArrayRef.current = new Uint8Array(analyserRef.current.frequencyBinCount);
            // @ts-ignore
            sourceRef.current = audioContextRef.current.createMediaStreamSource(audio);
            // @ts-ignore
            sourceRef.current.connect(analyserRef.current);
            // @ts-ignore
            rafIdRef.current = requestAnimationFrame(tick);

            return () => {
                // @ts-ignore
                cancelAnimationFrame(rafIdRef.current);
                // @ts-ignore
                analyserRef.current.disconnect();
                // @ts-ignore
                sourceRef.current.disconnect();
            };
        }, [audio]);

        const tick = () => {
            // @ts-ignore
            analyserRef.current.getByteTimeDomainData(dataArrayRef.current);
            const data : any = dataArrayRef.current
            setAudioData([...data]);
            // @ts-ignore
            rafIdRef.current = requestAnimationFrame(tick);
        };

        return <AudioVisualiser audioData={audioData} />;
    };

    return (
        <div>
            {audio ? <AudioAnalyser audio={audio} /> : ''}
        </div>
    );
};

export default AudioWave;