import title from "../chartOptions/title";
import {IPanelConfig} from "../../interfaces/panel/IPanelConfig";
import {tooltip} from "../chartOptions/tooltip";
import series from "../chartOptions/series";
import panelTypes from "../constants/PanelTypes";

const gaugeChart: IPanelConfig = {
    title: "Gauge Chart",
    type: panelTypes.gauge,
    isChart: true,
    description: "Use needles to show information as a reading on a dial.",
    icon: "/media/icons/duotune/graphs/gra002.svg",
    iconClass: 'svg-icon-2x svg-icon-error',
    options: [
        {...title},
        {...tooltip},
        {...series}
    ],
    series: [
        {
            type: 'gauge',
            progress: {
                show: true
            },
            detail: {
                valueAnimation: true,
                formatter: '{value}'
            },
            data: [
                {
                    value: 50,
                    name: 'SCORE'
                }
            ]
        }
    ]
}

export {gaugeChart}