/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {AsideMenuItem} from './AsideMenuItem'

export function AsideMenuMain() {
    const intl = useIntl()

    return (
        <>
            <div className='menu-item'>
                <div className='menu-content pt-8 pb-2'>
                    <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Admin</span>
                </div>
            </div>
            <AsideMenuItem
                to='/dashboards'
                icon='/media/icons/duotune/layouts/lay001.svg'
                title={intl.formatMessage({id: 'MENU.DASHBOARDS'})}
                fontIcon='bi-app-indicator'
            />
            <AsideMenuItem
                to='/practiceList'
                icon='/media/icons/duotune/medicine/med005.svg'
                title={intl.formatMessage({id: 'MENU.PRACTICE_LIST'})}
                fontIcon='bi-layers'
            />
            <div className='menu-item'>
                <div className='menu-content pt-8 pb-2'>
                    <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Practices</span>
                </div>
            </div>
            <AsideMenuItem
                to='/coordinator-dashboard'
                icon='/media/icons/duotune/abstract/abs026.svg'
                title={intl.formatMessage({id: 'MENU.COORDINATOR_DASHBOARD'})}
                fontIcon='bi-layers'
            />
            {/*<AsideMenuItemWithSub*/}
            {/*    to='/crafted/pages'*/}
            {/*    title='Pages'*/}
            {/*    fontIcon='bi-archive'*/}
            {/*    icon='/media/icons/duotune/general/gen022.svg'*/}
            {/*>*/}
            {/*    <AsideMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>*/}
            {/*        <AsideMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true}/>*/}
            {/*        <AsideMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true}/>*/}
            {/*        <AsideMenuItem to='/crafted/pages/profile/campaigns' title='Campaigns' hasBullet={true}/>*/}
            {/*        <AsideMenuItem to='/crafted/pages/profile/documents' title='Documents' hasBullet={true}/>*/}
            {/*        <AsideMenuItem*/}
            {/*            to='/crafted/pages/profile/connections'*/}
            {/*            title='Connections'*/}
            {/*            hasBullet={true}*/}
            {/*        />*/}
            {/*    </AsideMenuItemWithSub>*/}

            {/*    <AsideMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>*/}
            {/*        <AsideMenuItem*/}
            {/*            to='/crafted/pages/wizards/horizontal'*/}
            {/*            title='Horizontal'*/}
            {/*            hasBullet={true}*/}
            {/*        />*/}
            {/*        <AsideMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true}/>*/}
            {/*    </AsideMenuItemWithSub>*/}
            {/*</AsideMenuItemWithSub>*/}
            <div className='menu-item'>
                <div className='menu-content pt-8 pb-2'>
                    <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Patients</span>
                </div>
            </div>
            <AsideMenuItem
                to='/patientDashboard'
                icon='/media/icons/duotune/communication/com005.svg'
                title={intl.formatMessage({id: 'MENU.PATIENT_DASHBOARD'})}
                fontIcon='bi-layers'
            />
            <div className='menu-item'>
                <div className='menu-content pt-8 pb-2'>
                    <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Appointments</span>
                </div>
            </div>
            <AsideMenuItem
                to='/joinCall'
                icon='/media/icons/duotune/communication/com006.svg'
                title={intl.formatMessage({id: 'MENU.JOIN_CALL'})}
                fontIcon='bi-layers'
            />
            <AsideMenuItem
                to='/joinCall-doc'
                icon='/media/icons/duotune/communication/com006.svg'
                title={intl.formatMessage({id: 'MENU.JOIN_CALL_DOC'})}
                fontIcon='bi-layers'
            />
            <div className='menu-item'>
                <div className='menu-content pt-8 pb-2'>
                    <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Doctor</span>
                </div>
            </div>
            <AsideMenuItem
                to='/doctorDashboard'
                icon='/media/icons/duotune/layouts/lay008.svg'
                title={intl.formatMessage({id: 'MENU.DOCTOR_DASHBOARD'})}
                fontIcon='bi-layers'
            />
        </>
    )
}
