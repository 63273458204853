import React, {FC, useEffect, useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {useParams} from 'react-router-dom'
import {iconList} from '../../../../_metronic/helpers/constants'
import {IDashboard} from '../../../interfaces/IDashboard'
import {
  dashboardList,
  getDashboard,
  updateDashboardList,
} from '../helpers/dashboardData/DashboardData'
import {useDashboardEditorContext} from '../../../contexts/dashboard/DashboardEditorContext'

const GeneralSetting: FC = () => {
  const [selected, setSelected] = useState(1)
  const {id} = useParams()

  const [changeIcon, setChangeIcon] = useState(false)
  const [iconClass, setIconClass] = useState('svg-icon-2hx svg-icon-muted')
  const [name, setName] = useState('')
  const [description, setDescription] = useState<any>('')
  const [icon, setIcon] = useState('')
  const [currentDashboard, setCurrentDashboard] = useState<IDashboard>()
  const [allDashboardList, setAllDashboardList] = useState<IDashboard[]>()
  const {setDashboards} = useDashboardEditorContext()

  useEffect(() => {
    const dashboard = getDashboard(Number(id))
    setCurrentDashboard(dashboard ? dashboard : undefined)
    if (dashboard) {
      setName(dashboard.name)
      setDescription(dashboard.description)
      setIcon(dashboard.icon)
      setIconClass(dashboard.iconClass ? dashboard.iconClass : 'svg-icon-2hx svg-icon-primary')
    }
    setAllDashboardList(dashboardList ? dashboardList() : undefined)
  }, [id])

  return (
    <div>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <div className='mb-7 col-6'>
              <label className='form-label fw-bold'>Name</label>
              <input
                type='text'
                className='form-control'
                placeholder={currentDashboard ? currentDashboard.name : 'Dashboard Name'}
                value={name}
                onChange={(e) => {
                  setName(e.target.value)
                }}
              />
            </div>
          </div>

          <div className='col-12'>
            <div className='mb-7 col-6'>
              <label className='form-label fw-bold'>Description</label>
              <input
                type='text'
                className='form-control'
                placeholder={
                  currentDashboard ? currentDashboard.description : 'Dashboard Description'
                }
                onKeyUp={(e) => {
                  // @ts-ignore
                  setDescription(e.target.value)
                  e.preventDefault()
                }}
              />
            </div>
          </div>
          <div className='col-12'>
            <div className='mb-7 col-6'>
              <label className='form-label fw-bold'>Select Icon</label>

              <div
                onClick={(e) => {
                  setChangeIcon(!changeIcon)
                  e.preventDefault()
                }}
                className='symbol symbol-40px ms-5'
              >
                <span className='symbol-label bg-light-success'>
                  <KTSVG path={icon ? icon : currentDashboard?.icon} className={iconClass} />
                </span>
              </div>
              {/*begin::cardList*/}
              {changeIcon && (
                <div
                  className='card card-custom card-stretch'
                  style={{
                    position: 'absolute',
                    zIndex: '1000',
                    marginTop: '0px',
                    width: '300px',
                  }}
                >
                  <div className='card-header'>
                    <div className='card-title'>Select Icon</div>
                    <div placeholder='select color' className='card-toolbar'>
                      <select
                        onChange={(e) => {
                          // @ts-ignore
                          console.log('select color', e.target.value)
                          // @ts-ignore
                          setIconClass(e.target.value)
                        }}
                        name='color'
                        id=''
                        className='form-control form-select'
                      >
                        <option value='svg-icon-2hx svg-icon-muted'>Icon Color</option>
                        <option value='svg-icon-2hx svg-icon-warning'>Warning</option>
                        <option value='svg-icon-2hx svg-icon-danger'>Danger</option>
                        <option value='svg-icon-2hx svg-icon-primary'>Primary</option>
                        <option value='svg-icon-2hx svg-icon-Secondary'>Secondary</option>
                        <option value='svg-icon-2hx svg-icon-white'>White</option>
                        <option value='svg-icon-2hx svg-icon-light'>Light</option>
                        <option value='svg-icon-2hx svg-icon-info'>Info</option>
                        <option value='svg-icon-2hx svg-icon-success'>Success</option>
                        <option value='svg-icon-2hx svg-icon-dark'>Dark</option>
                        <option value='svg-icon-2hx svg-icon-muted'>Muted</option>
                      </select>
                      <div
                        style={{
                          position: 'absolute',
                          zIndex: '1050',
                          marginTop: '-75px',
                          marginLeft: '143px',
                        }}
                        onClick={() => {
                          setChangeIcon(false)
                        }}
                      >
                        <KTSVG
                          path='/media/icons/duotune/general/gen040.svg'
                          className='svg-icon-danger svg-icon-2hx'
                        />
                      </div>
                    </div>
                  </div>
                  <div className='card-body card-scroll h-200px'>
                    <div className='row col-12'>
                      {iconList.map((icon) => {
                        return (
                          <div
                            key={icon.value}
                            onClick={(e) => {
                              setIcon(icon.value)
                              e.preventDefault()
                              e.stopPropagation()
                              setChangeIcon(false)
                            }}
                            className='symbol col-2 symbol-40px m-2'
                          >
                            <span className='symbol-label'>
                              <KTSVG path={icon.value} className={iconClass} />
                            </span>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
              )}
              {/*begin::CardList*/}
            </div>
          </div>

          {/*<div className="col-12">*/}
          {/*    <div className="mb-7 col-4">*/}
          {/*        <label className="form-label fw-bold">Tag</label>*/}
          {/*        <input*/}
          {/*            type="text"*/}
          {/*            className="form-control"*/}
          {/*            placeholder="New Tag (Enter Key to Add)"*/}
          {/*        />*/}
          {/*    </div>*/}
          {/*</div>*/}
          {/*<hr className='mt-5'/>*/}
          {/*<h2>Options shown below are dummy & will be functional Soon...</h2>*/}
          {/*<hr className="mb-5"/>*/}
          {/*<div className="col-12">*/}
          {/*    <div className="mb-7 col-6">*/}
          {/*        <label className="form-label fw-bold">Folder</label>*/}
          {/*        <select className="form-select" aria-label="Select example">*/}
          {/*            <option className="py-4" value="1">General</option>*/}
          {/*        </select>*/}
          {/*    </div>*/}
          {/*</div>*/}
          <div className='col-12'>
            <div className='mb-7 col-6'>
              <label className='form-label fw-bold'>Editable</label>
              <div className='fs-6'>
                Set to read-only to disable all editing. Reload the dashboard for changes to take
                effect.
              </div>
              <div className='col-12 rounded-pill bg-secondary w-250px mt-3'>
                <span className=''>
                  <button
                    className={`btn ${
                      selected === 1 ? 'btn-primary' : 'btn-secondary'
                    } rounded-pill w-125px`}
                    onClick={() => setSelected(1)}
                  >
                    Editable
                  </button>
                </span>
                <span className=''>
                  <button
                    className={`btn ${
                      selected === 2 ? 'btn-primary' : 'btn-secondary'
                    } rounded-pill w-125px`}
                    onClick={() => setSelected(2)}
                  >
                    ReadOnly
                  </button>
                </span>
              </div>
            </div>
          </div>

          {/*<div className="accordion accordion-icon-toggle" id="kt_accordion_2">*/}
          {/*    <div className="mb-5">*/}
          {/*        <div*/}
          {/*            className="accordion-header py-3 d-flex"*/}
          {/*            data-bs-toggle="collapse"*/}
          {/*            data-bs-target="#kt_accordion_2_item_1"*/}
          {/*        >*/}
          {/*<span className="accordion-icon">*/}
          {/*    <KTSVG*/}
          {/*        className="svg-icon svg-icon-4"*/}
          {/*        path="/media/icons/duotune/arrows/arr064.svg"*/}
          {/*    />*/}
          {/*</span>*/}
          {/*            <h3 className="fs-4 text-gray-800 fw-bold mb-0 ms-4">*/}
          {/*                Time options*/}
          {/*            </h3>*/}
          {/*        </div>*/}
          {/*        <div id="kt_accordion_2_item_1"*/}
          {/*             className="fs-6 collapse show ps-10 mb-2"*/}
          {/*             data-bs-parent="#kt_accordion_2">*/}
          {/*            <div className="mb-7 col-6">*/}
          {/*                <label className="form-label fw-bold">Time Zone</label>*/}
          {/*                <select className="form-select" aria-label="Select example">*/}
          {/*                    <option>Default</option>*/}
          {/*                    <option className="" value="1">Browser Time</option>*/}
          {/*                </select>*/}
          {/*            </div>*/}
          {/*        </div>*/}
          {/*        <div id="kt_accordion_2_item_1"*/}
          {/*             className="fs-6 collapse show ps-10 mb-2"*/}
          {/*             data-bs-parent="#kt_accordion_2">*/}
          {/*            <div className="mb-7 col-6">*/}
          {/*                <label className="form-label fw-bold">Week Start</label>*/}
          {/*                <select className="form-select" aria-label="Select example">*/}
          {/*                    <option>Default</option>*/}
          {/*                    <option className="" value="1">Saturday</option>*/}
          {/*                </select>*/}
          {/*            </div>*/}
          {/*        </div>*/}
          {/*        <div id="kt_accordion_2_item_1"*/}
          {/*             className="fs-6 collapse show ps-10 mb-2"*/}
          {/*             data-bs-parent="#kt_accordion_2">*/}
          {/*            <div className="mb-7 col-6">*/}
          {/*                <label className="form-label fw-bold">Auto refresh</label>*/}
          {/*                <div*/}
          {/*                    className="fs-6 mb-1"*/}
          {/*                >*/}
          {/*                    Define the auto refresh intervals that should be available in the auto refresh list.*/}
          {/*                </div>*/}
          {/*                <input*/}
          {/*                    type="text"*/}
          {/*                    className="form-control"*/}
          {/*                    placeholder="1m, 1s,"*/}
          {/*                />*/}
          {/*            </div>*/}
          {/*        </div>*/}
          {/*        <div id="kt_accordion_2_item_1"*/}
          {/*             className="fs-6 collapse show ps-10 mb-2"*/}
          {/*             data-bs-parent="#kt_accordion_2">*/}
          {/*            <div className="mb-7 col-6">*/}
          {/*                <label className="form-label fw-bold">Now delay</label>*/}
          {/*                <div*/}
          {/*                    className="fs-6 mb-1"*/}
          {/*                >*/}
          {/*                    Exclude recent data that may be incomplete.*/}
          {/*                </div>*/}
          {/*                <input*/}
          {/*                    type="text"*/}
          {/*                    className="form-control"*/}
          {/*                    placeholder="0m"*/}
          {/*                />*/}
          {/*            </div>*/}
          {/*        </div>*/}
          {/*        <div id="kt_accordion_2_item_1"*/}
          {/*             className="fs-6 collapse show ps-10 mb-2"*/}
          {/*             data-bs-parent="#kt_accordion_2">*/}
          {/*            <label className="form-label fw-bold" htmlFor="hideTimePicker">Hide Time Picker</label>*/}
          {/*            <div className="form-check form-switch form-check-custom form-check-success">*/}
          {/*                <div className="row">*/}
          {/*                    <div className="col-12">*/}
          {/*                        <input className="form-check-input h-20px w-40px" type="checkbox" value="" id="hideTimePicker"/>*/}
          {/*                    </div>*/}
          {/*                </div>*/}

          {/*            </div>*/}
          {/*        </div>*/}
          {/*        <div id="kt_accordion_2_item_1"*/}
          {/*             className="fs-6 collapse show ps-10 mb-2"*/}
          {/*             data-bs-parent="#kt_accordion_2">*/}
          {/*            <label className="form-label fw-bold" htmlFor="refreshLive">Refresh live dashboards</label>*/}
          {/*            <div className="form-check form-switch form-check-custom form-check-success">*/}
          {/*                <div className="row">*/}
          {/*                    <div className="col-12">*/}
          {/*                        <input className="form-check-input h-20px w-40px" type="checkbox" value="" id="refreshLive"/>*/}
          {/*                    </div>*/}
          {/*                </div>*/}
          {/*            </div>*/}
          {/*        </div>*/}
          {/*    </div>*/}
          {/*</div>*/}
          {/*<div className="accordion accordion-icon-toggle" id="kt_accordion_3">*/}
          {/*    <div className="mb-5">*/}
          {/*        <div*/}
          {/*            className="accordion-header py-3 d-flex"*/}
          {/*            data-bs-toggle="collapse"*/}
          {/*            data-bs-target="#kt_accordion_3_item_1"*/}
          {/*        >*/}
          {/*<span className="accordion-icon">*/}
          {/*    <KTSVG*/}
          {/*        className="svg-icon svg-icon-4"*/}
          {/*        path="/media/icons/duotune/arrows/arr064.svg"*/}
          {/*    />*/}
          {/*</span>*/}
          {/*            <h3 className="fs-4 text-gray-800 fw-bold mb-0 ms-4">*/}
          {/*                Panel options*/}
          {/*            </h3>*/}
          {/*        </div>*/}
          {/*        <div id="kt_accordion_3_item_1"*/}
          {/*             className="fs-6 collapse show ps-10 mb-2"*/}
          {/*             data-bs-parent="#kt_accordion_3">*/}
          {/*            <label className="form-label fw-bold">Graph Tooltip</label>*/}
          {/*            <div*/}
          {/*                className="fs-6 mb-1"*/}
          {/*            >*/}
          {/*                Controls tooltip and hover highlight behavior across different panels*/}
          {/*            </div>*/}

          {/*            <div className="col-12 rounded-pill bg-secondary w-450px mt-3">*/}
          {/*                <span className="">*/}
          {/*                    <button className={`btn ${graphTooltip === 1 ? "btn-primary": "btn-secondary"} rounded-pill w-125px fs-5`} onClick={() => setGraphTooltip(1)}>Default</button>*/}
          {/*                </span>*/}
          {/*                <span className="">*/}
          {/*                    <button className={`btn ${graphTooltip === 2 ? "btn-primary": "btn-secondary"} rounded-pill w-175px fs-5`} onClick={() => setGraphTooltip(2)}>Shared Crosshair</button>*/}
          {/*                </span>*/}
          {/*                <span className="">*/}
          {/*                    <button className={`btn ${graphTooltip === 3 ? "btn-primary": "btn-secondary"} rounded-pill w-150px fs-5`} onClick={() => setGraphTooltip(3)}>Shared Tooltip</button>*/}
          {/*                </span>*/}
          {/*            </div>*/}
          {/*        </div>*/}
          {/*    </div>*/}
          {/*</div>*/}
          <div className='col-12 mt-4 text-end'>
            <button
              className='btn btn-lg btn-primary me-2 '
              onClick={() => {
                const cdIndex = allDashboardList?.findIndex(
                  (obj) => obj.id === currentDashboard?.id
                )
                const newArray = allDashboardList
                if (newArray && (cdIndex === 0 || cdIndex)) {
                  console.log('condition is true')
                  newArray[cdIndex].name = name
                  newArray[cdIndex].description = description
                  newArray[cdIndex].icon = icon
                  newArray[cdIndex].iconClass = iconClass
                  newArray[cdIndex].lastUpdated = new Date()

                  updateDashboardList(newArray)
                  setDashboards([...newArray])
                }
              }}
            >
              Save Changes
            </button>
            <button className='btn btn-lg btn-danger '>Delete Dashboard</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GeneralSetting
