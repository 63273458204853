import {IDashboard} from "../../../../interfaces/IDashboard";

export const dashboardListName = 'dashboards'
export const dashboardList = () : IDashboard [] => {
    const dashboards = localStorage.getItem(dashboardListName)
    return  dashboards ? JSON.parse(dashboards) : null
}
export const updateDashboardList = (dashboards: IDashboard[]) => {
    localStorage.setItem(dashboardListName, JSON.stringify(dashboards))
}
export const getDashboard = (id: any) => {
    const list = dashboardList()
    return list ? list.find(c => c.id === id) : null
}