import {IPanelConfig} from "../interfaces/panel/IPanelConfig";
import {basicLineChart} from "./charts/LineChartConfiguration";
import {basicBarChart} from "./charts/BarChartConfiguration";
import {pieChart} from "./charts/PieChartConfiguration";
import {heatMapChart} from "./charts/HeatMapChartConfiguration";
import {basicScatterChart} from "./charts/ScatterChartConfiguration";
import {gaugeChart} from "./charts/GaugeChartConfiguration";
import {iconAndTextPanel} from "./others/IconAndTextPanel";

const panelList: Array<IPanelConfig> = [
    basicLineChart,
    basicBarChart,
    pieChart,
    heatMapChart,
    basicScatterChart,
    gaugeChart,
    iconAndTextPanel
]

export default JSON.stringify(panelList)