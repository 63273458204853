import React, {createContext, useContext, useState} from "react";
interface IAudioCallContext {
    callActive: boolean,
    setCallActive: (val: boolean) => void
}
export const AudioCallContext = createContext<IAudioCallContext>({ callActive: false, setCallActive: (val) => {}})
const useAudioCallContext = () => {
    return useContext(AudioCallContext)
}

const AudioCallProvider = ({children}: any) => {
    const [callActive, setCallActive] = useState(false)
    return <AudioCallContext.Provider value={{
        callActive,
        setCallActive
    }}>
        {children}
    </AudioCallContext.Provider>
}

export {useAudioCallContext, AudioCallProvider}