import React, {createContext, useContext, useState} from "react";
import {IPanelConfig} from "../../interfaces/panel/IPanelConfig";
import panelList from "../../panelConfiguration/PanelList";
import {IDashboard} from "../../interfaces/IDashboard";
import {dashboardList} from "../../pages/dashboard/helpers/dashboardData/DashboardData";
interface IDashboardEditorContext {
    panel: IPanelConfig | null;
    setPanel: (chart: any) => void;
    dashboardId?: any;
    setDashboardId: (val: any) => void
    dashboards: IDashboard[],
    setDashboards: (val: any) => void,
    setDashboard: (val: any) => void,
    dashboard: IDashboard|null,
}
const dashboardsList = dashboardList() ? dashboardList() : []
export const DashboardEditorContext = createContext<IDashboardEditorContext>({
    panel: JSON.parse(panelList)[0],
    setPanel: () => {},
    dashboardId: 0,
    dashboards: dashboardsList,
    setDashboardId: () => {},
    setDashboards: () => {},
    dashboard: null,
    setDashboard: () => {},
})
const useDashboardEditorContext = () => {
    return useContext(DashboardEditorContext)
}

const DashboardEditorProvider = ({children}: any) => {
    const [selectedPanel, setPanel] = useState<IPanelConfig>({...JSON.parse(panelList)[0]})
    const [dashboardId, setDashboardId] = useState(0)
    const [dashboards, setDashboards] = useState<IDashboard[]>([])
    const [dashboard, setDashboard] = useState<IDashboard | null>(null)
    return <DashboardEditorContext.Provider value={{
        panel: selectedPanel,
        setPanel,
        dashboardId: dashboardId,
        setDashboardId,
        dashboards,
        setDashboards,
        dashboard,
        setDashboard
    }}>
        {children}
    </DashboardEditorContext.Provider>
}

export {useDashboardEditorContext, DashboardEditorProvider}