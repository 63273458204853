import {FieldType} from "../../enums/FieldType";
import {IPanelOption} from "../../interfaces/panel/IPanelOption";

const visualMap : IPanelOption = {
    title: "Visual Map",
    optionName: 'visualMap',
    isShow: true,
    fields: [
        {
            label: "Min",
            name: "min",
            type: FieldType.Number,
            tooltipMessage: 'any',
            valueType: "number",
            isRequired: false,
            value: 0
        },
        {
            label: "Max",
            name: "max",
            type: FieldType.Number,
            tooltipMessage: 'any',
            valueType: "number",
            isRequired: false,
            value: 10
        },
        {
            label: "Left",
            name: 'left',
            type: FieldType.Select,
            tooltipMessage: 'any',
            valueType: "text",
            isRequired: false,
            value: {label: 'Center', value: 'center'},
            selectOptions: [
                {label: 'Auto', value: 'auto'},
                {label: 'Left', value: 'left'},
                {label: 'Center', value: 'center'},
                {label: 'Right', value: 'right'}
            ]
        },
        {
            label: "Top",
            name: 'top',
            type: FieldType.Select,
            tooltipMessage: 'any',
            valueType: "text",
            isRequired: false,
            value: {label: 'Bottom', value: 'bottom'},
            selectOptions: [
                {label: 'Auto', value: 'auto'},
                {label: 'Top', value: 'top'},
                {label: 'Middle', value: 'middle'},
                {label: 'Bottom', value: 'bottom'}
            ]
        },
        {
            label: "Orient",
            name: 'orient',
            type: FieldType.Select,
            tooltipMessage: 'any',
            valueType: "text",
            isRequired: false,
            value: {label: 'Horizontal', value: 'horizontal'},
            selectOptions: [
                {label: 'Horizontal', value: 'horizontal'},
                {label: 'Vertical', value: 'vertical'}
            ]
        },
        {
            label: "Calculable",
            name: "calculable",
            type: FieldType.SwitchButton,
            tooltipMessage: 'any',
            valueType: "number",
            isRequired: false,
            value: true
        }
    ]
}

export default visualMap