import title from "../chartOptions/title";
import legend from "../chartOptions/legend";
import {IPanelConfig} from "../../interfaces/panel/IPanelConfig";
import series from "../chartOptions/series";
import {tooltip} from "../chartOptions/tooltip";
import panelTypes from "../constants/PanelTypes";

const basicBarChart: IPanelConfig = {
    title: "Bar Chart",
    type: panelTypes.bar,
    isChart: true,
    description: "plots numeric values for levels of a categorical feature as bars.",
    icon: "/media/icons/duotune/graphs/gra004.svg",
    iconClass: 'svg-icon-2x svg-icon-warning',
    options: [
        {...title},
        {...tooltip},
        {...legend},
        {...series}
],
    xAxis: [{
        type: "category",
        data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]
    }],
    yAxis: [{
        type: "value"
    }],
    series:  [{
    type: 'bar',
    barWidth: "60%",
    data: [10, 52, 200, 334, 390, 330, 220, 1000]
}]
}

export {basicBarChart}