import {FieldType} from "../../enums/FieldType";
import {IPanelOption} from "../../interfaces/panel/IPanelOption";

const series : IPanelOption = {
    title: "Series Options",
    optionName: 'series',
    isShow: true,
    fields: [
        {
            label: "Name",
            name: "name",
            type: FieldType.Text,
            tooltipMessage: 'Series name used for displaying in tooltip and filtering with legend, or updating data and configuration with setOption',
            valueType: "text",
            isRequired: false,
            value: ''
        }
    ]
}

export default series