import React from 'react'
import clsx from 'clsx'
import {KTSVG} from '../../../../_metronic/helpers'
import {Link, useParams} from 'react-router-dom'

type Props = {
  toggleBtnClass2?: string
  toggleBtnIconClass?: string
  menuPlacement?: string
  menuTrigger?: string
}
const Settings = ({
  toggleBtnClass2 = '',
  toggleBtnIconClass = 'svg-icon-1',
  menuPlacement = 'bottom-end',
  menuTrigger = "{default: 'click'}",
}: Props) => {
  // const [activeSetting, setActiveSetting] = useState('')
  const {id: dashboardId} = useParams()
  return (
    <>
      {/* begin::Menu toggle */}
      <Link
        to={`/dashboard/${dashboardId}/general-setting`}
        className={clsx('btn btn-icon ', toggleBtnClass2)}
        data-kt-menu-trigger={menuTrigger}
        data-kt-menu-attach='parent'
        data-kt-menu-placement={menuPlacement}
      >
        {/*{calculatedMode === 'dark' && (*/}
        <KTSVG path='/media/icons/duotune/coding/cod001.svg' className={toggleBtnIconClass} />
      </Link>
      {/* begin::Menu toggle */}

      {/* begin::Menu */}
      {/*<div*/}
      {/*  className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-muted menu-active-bg menu-state-primary fw-semibold py-4 fs-base w-175px'*/}
      {/*  data-kt-menu='true'*/}
      {/*>*/}
      {/*  /!* begin::Menu item *!/*/}
      {/*  <div className='menu-item px-3 my-0'>*/}
      {/*    <Link*/}
      {/*      to={`/dashboard/${dashboardId}/general-setting`}*/}
      {/*      className={clsx('menu-link px-3 py-2', {active: activeSetting === 'general'})}*/}
      {/*      onClick={() => setActiveSetting('general')}*/}
      {/*    >*/}
      {/*      <span className='menu-title'>General</span>*/}
      {/*    </Link>*/}
      {/*  </div>*/}
      {/*  /!* end::Menu item *!/*/}

      {/*  /!* begin::Menu item *!/*/}
      {/*  <div className='menu-item px-3 my-0'>*/}
      {/*    <Link*/}
      {/*      to={`/dashboard/${dashboardId}/versions`}*/}
      {/*      className={clsx('menu-link px-3 py-2', {active: activeSetting === 'versions'})}*/}
      {/*      onClick={() => setActiveSetting('versions')}*/}
      {/*    >*/}
      {/*      <span className='menu-title'>Versions</span>*/}
      {/*    </Link>*/}
      {/*  </div>*/}
      {/*  /!* end::Menu item *!/*/}

      {/*  /!* begin::Menu item *!/*/}
      {/*  <div className='menu-item px-3 my-0'>*/}
      {/*    <Link*/}
      {/*      to={`/dashboard/${dashboardId}/permissions`}*/}
      {/*      className={clsx('menu-link px-3 py-2', {active: activeSetting === 'permissions'})}*/}
      {/*      onClick={() => setActiveSetting('permissions')}*/}
      {/*    >*/}
      {/*      <span className='menu-title'>Permissions</span>*/}
      {/*    </Link>*/}
      {/*  </div>*/}
      {/*  /!* end::Menu item *!/*/}
      {/*</div>*/}
      {/* end::Menu */}
    </>
  )
}

export {Settings}
