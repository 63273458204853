import title from "../chartOptions/title";
import legend from "../chartOptions/legend";
import {IPanelConfig} from "../../interfaces/panel/IPanelConfig";
import series from "../chartOptions/series";
import {tooltip} from "../chartOptions/tooltip";
import panelTypes from "../constants/PanelTypes";

const pieChart : IPanelConfig = {
    title: "Pie Chart",
    type: panelTypes.pie,
    isChart: true,
    description: "A pie chart divided into slices to illustrate numerical proportion.",
    icon: "/media/icons/duotune/graphs/gra010.svg",
    iconClass: 'svg-icon-2x svg-icon-danger',
    options: [
        {...title},
        {...tooltip},
        {...legend},
        {...series}
    ],
    series:  [
        {
            type: 'pie',
            radius: '50%',
            data: [
                { value: 1048, name: 'Search Engine' },
                { value: 735, name: 'Direct' },
                { value: 580, name: 'Email' },
                { value: 484, name: 'Union Ads' },
                { value: 300, name: 'Video Ads' }
            ],
            emphasis: {
                itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
            }
        }
    ]

}

export {pieChart}