import {IPanelConfig} from "../../interfaces/panel/IPanelConfig";
import panelTypes from "../constants/PanelTypes";
import {FieldType} from "../../enums/FieldType";
import {iconList} from "../../../_metronic/helpers/constants";

const iconAndTextPanel: IPanelConfig = {
    title: "Icon & Text Panel",
    type: panelTypes.iconAndText,
    isChart: false,
    description: "Used to design a panel having a logo and text",
    icon: "/media/icons/duotune/text/txt002.svg",
    iconClass: 'svg-icon-2x svg-icon-warning',
    options: [
        {
            title: "Panel Title",
            optionName: 'title',
            isShow: true,
            fields: [
                {
                    label: "Enter Title",
                    name: "text",
                    type: FieldType.Text,
                    tooltipMessage: 'any',
                    valueType: "text",
                    isRequired: false,
                    value: ''
                }
            ]},{
            title: "Panel Icon",
            optionName: 'icon',
            isShow: true,
            fields: [
                {
                    label: "Select Icon/Image",
                    name: "icon",
                    type: FieldType.SelectHavingHTMLOptions,
                    tooltipMessage: 'any',
                    valueType: "text",
                    isRequired: false,
                    selectOptions: iconList.map(i => {
                        return {label: i.label, value: i.value, icon: i.value}
                    }),
                    value: ''
                }
            ]}
    ]
}

export {iconAndTextPanel}