import React, {FC} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import PaginationComponent from '../components/PaginationComponent'

const Permissions: FC = () => {
  return (
    <div>
      <table className='table table-striped gy-7 gs-4'>
        <tbody>
          <tr className=''>
            <td className='w-20px '>
              {' '}
              <KTSVG path='/media/icons/duotune/general/gen048.svg' className='svg-icon-1' />
            </td>
            <td className='fs-4 '>Admin (Role)</td>
            <td></td>
            <td className='w-200px fs-4 '></td>
            <td className='w-40px fs-4 '>Can</td>
            <td className='w-200px pt-4'>
              {' '}
              <select className='form-select ' disabled aria-label='Select example'>
                <option value='1'>Admin</option>
              </select>
            </td>
            <td></td>
          </tr>
          <tr className=''>
            <td className='w-20px '>
              {' '}
              <KTSVG path='/media/icons/duotune/general/gen048.svg' className='svg-icon-1' />
            </td>
            <td className='fs-4 '>Editor (Role)</td>
            <td></td>
            <td className='w-200px fs-4 text-muted'>Default Permission</td>
            <td className='w-40px fs-4 '>Can</td>
            <td className='w-200px pt-4'>
              {' '}
              <select className='form-select' aria-label='Select example'>
                <option value='1'>Admin</option>
                <option value='2'>Edit</option>
                <option value='3'>View</option>
              </select>
            </td>
            <td></td>
          </tr>
          <tr className=''>
            <td className='w-20px '>
              {' '}
              <KTSVG path='/media/icons/duotune/general/gen048.svg' className='svg-icon-1' />
            </td>
            <td className='fs-4 '>Viewer (Role)</td>
            <td></td>
            <td className='w-200px fs-4 text-muted'>Default Permission</td>
            <td className='w-40px fs-4 '>Can</td>
            <td className='w-200px pt-4'>
              {' '}
              <select className='form-select' aria-label='Select example'>
                <option value='1'>Admin</option>
                <option value='2'>Edit</option>
                <option value='3'>View</option>
              </select>
            </td>
            <td></td>
          </tr>
        </tbody>
      </table>
      <PaginationComponent />
    </div>
  )
}

export default Permissions
