import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import SettingsPage from '../pages/dashboard/settings/SettingsPage'

const PrivateRoutes = () => {
  const Dashboards = lazy(() => import('../pages/dashboard/AllDashboards'))
  const DashboardWrapper = lazy(() => import('../pages/dashboard/DashboardWrapper'))
  const FollowUp = lazy(() => import('../pages/practices/coordinator/followup/FollowUp'))
  const CoordinatorTable = lazy(
    () => import('../pages/practices/coordinator/components/CoordinatorTable')
  )
  const CoordinatorDashboard = lazy(
    () => import('../pages/practices/coordinator/CoordinatorDashboard')
  )
  const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))
  const ManagePracticeList = lazy(() => import('../pages/admin/ManagePracticeList'))
  const PracticeManagement = lazy(
    () => import('../pages/admin/practiceManagement/PracticeManagement')
  )
  const PracticeList = lazy(() => import('../pages/admin/PracticeList'))
  const GlucoseGraph = lazy(
    () => import('../pages/patient/dashboard/patientDashSubMenuPages/GlucoseGraph')
  )
  const GlucoseList = lazy(
    () => import('../pages/patient/dashboard/patientDashSubMenuPages/GlucoseList')
  )
  const BloodPressureList = lazy(
    () => import('../pages/patient/dashboard/patientDashSubMenuPages/BloodPressureList')
  )
  const Charts = lazy(() => import('../pages/patient/dashboard/patientDashSubMenuPages/Charts'))
  const Profile = lazy(() => import('../pages/patient/dashboard/patientDashSubMenuPages/Profile'))
  const SMS = lazy(() => import('../pages/patient/dashboard/patientDashSubMenuPages/SMS'))
  const PatientDashboard = lazy(() => import('../pages/patient/dashboard/Dashboard'))
  const DashboardEditor = lazy(() => import('../pages/dashboard/DashboardEditor'))
  const PanelDetail = lazy(() => import('../pages/dashboard/PanelDetail'))
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  const ApacheChart = lazy(() => import('../pages/ApacheChartExample'))
  const CallPage = lazy(() => import('../pages/call/CallScreen'))
  const CallDashboard = lazy(() => import('../pages/call/CallDashboard'))
  const DoctorDashboard = lazy(() => import('../pages/doctor/dashboard/Dashboard'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to={`/dashboards`} />} />
        {/* Pages */}
        <Route
          path='coordinator-dashboard/:id/table'
          element={
            <SuspensedView>
              <CoordinatorTable />
            </SuspensedView>
          }
        />
        <Route
          path='coordinator-dashboard'
          element={
            <SuspensedView>
              <CoordinatorDashboard />
            </SuspensedView>
          }
        />
        <Route
          path='dashboard/:id/general-setting'
          element={
            <SuspensedView>
              <SettingsPage />
            </SuspensedView>
          }
        />

        <Route
          path='dashboard/:dashId/panel-detail/:id'
          element={
            <SuspensedView>
              <PanelDetail />
            </SuspensedView>
          }
        />
          <Route
          path='dashboard-editor/:dashId'
          element={
            <SuspensedView>
              <DashboardEditor />
            </SuspensedView>
          }
        />
        <Route
          path='dashboards'
          element={
            <SuspensedView>
              <Dashboards />
            </SuspensedView>
          }
        />
        <Route
          path='dashboard/:id'
          element={
            <SuspensedView>
              <DashboardWrapper />
            </SuspensedView>
          }
        />

        <Route
          path='builder'
          element={
            <SuspensedView>
              <BuilderPageWrapper />
            </SuspensedView>
          }
        />

        <Route path='menu-test' element={<MenuTestPage />} />

        <Route
          path='practiceList'
          element={
            <SuspensedView>
              <PracticeList />
            </SuspensedView>
          }
        />

        <Route
          path='managePracticeList'
          element={
            <SuspensedView>
              <ManagePracticeList />
            </SuspensedView>
          }
        />

        <Route
          path='practiceManagement'
          element={
            <SuspensedView>
              <PracticeManagement />
            </SuspensedView>
          }
        />

        <Route
          path='patientDashboard'
          element={
            <SuspensedView>
              <PatientDashboard />
            </SuspensedView>
          }
        />

        <Route
          path='SMS'
          element={
            <SuspensedView>
              <SMS />
            </SuspensedView>
          }
        />

        <Route
          path='userProfile'
          element={
            <SuspensedView>
              <Profile />
            </SuspensedView>
          }
        />

        <Route
          path='charts'
          element={
            <SuspensedView>
              <Charts />
            </SuspensedView>
          }
        />

        <Route
          path='BloodPressureList'
          element={
            <SuspensedView>
              <BloodPressureList />
            </SuspensedView>
          }
        />

        <Route
          path='GlucoseList'
          element={
            <SuspensedView>
              <GlucoseList />
            </SuspensedView>
          }
        />

        <Route path='GlucoseGraph' element={<GlucoseGraph />} />
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/ApacheChart/*'
          element={
            <SuspensedView>
              <ApacheChart />
            </SuspensedView>
          }
        />
          <Route
              path='joinCall'
              element={
                  <SuspensedView>
                      <CallPage role={'patient'}/>
                  </SuspensedView>
              }
          />
          <Route
              path='joinCall-doc'
              element={
                  <SuspensedView>
                      <CallPage role={'doc'} />
                  </SuspensedView>
              }
          />
          <Route
              path='doctorDashboard'
              element={
                  <SuspensedView>
                      <DoctorDashboard />
                  </SuspensedView>
              }
          />
        <Route
          path='followUp/:id'
          element={
            <SuspensedView>
              <FollowUp />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
        <Route
            path='callDashboard'
            element={
                <SuspensedView>
                    <CallDashboard />
                </SuspensedView>
            }
        />
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
