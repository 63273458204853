import React from 'react'

const PaginationComponent = () => {
  return (
    <ul className='pagination mb-5'>
      <li className='page-item previous disabled'>
        <button className='page-link'>
          <i className='previous'></i>
        </button>
      </li>
      <li className='page-item'>
        <button className='page-link'>1</button>
      </li>
      <li className='page-item active'>
        <button className='page-link'>2</button>
      </li>
      <li className='page-item'>
        <button className='page-link'>3</button>
      </li>
      <li className='page-item'>
        <button className='page-link'>4</button>
      </li>
      <li className='page-item'>
        <button className='page-link'>5</button>
      </li>
      <li className='page-item'>
        <button className='page-link'>6</button>
      </li>
      <li className='page-item next'>
        <button className='page-link'>
          <i className='next'></i>
        </button>
      </li>
    </ul>
  )
}

export default PaginationComponent
