import title from "../chartOptions/title";
import legend from "../chartOptions/legend";
import {IPanelConfig} from "../../interfaces/panel/IPanelConfig";
import series from "../chartOptions/series";
import {tooltip} from "../chartOptions/tooltip";
import panelTypes from "../constants/PanelTypes";

const basicLineChart : IPanelConfig = {
    title: "Line Chart",
    type: panelTypes.line,
    isChart: true,
    description: "A chart that connects a series of data points with a continuous line.",
    icon: "/media/icons/duotune/graphs/gra012.svg",
    iconClass: 'svg-icon-2x svg-icon-success',
    options: [
        {...title},
        {...tooltip},
        {...legend},
        {...series}
    ],
    xAxis: [{
        type: "category",
        data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]
    }],
    yAxis: [{
        type: "value"
    }],
    series:  [{
        type: 'line',
        data: [10, 52, 200, 334, 390, 330, 220, 1000]
    }]

}

export {basicLineChart}