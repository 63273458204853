import {AbilityBuilder, Ability} from '@casl/ability'

const { build } = new AbilityBuilder(Ability);
export default build();
export const updateAbility = (ability: any, user: any) => {
    const { can, rules } = new AbilityBuilder(Ability);

    if (user.role === 'admin') {
        can('manage', 'all');
    } else {
        can('read', 'all');
        can('delete', 'post');
    }

    ability.update(rules);
}
